import { useListContext, useNotify, Button } from "react-admin";
import { SyncAltOutlined } from "@mui/icons-material";
import { BulkSyncUserData } from "../utils/canopie-api";

const SyncUserData = () => {
  const { selectedIds, data } = useListContext();
  const notify = useNotify();

  const syncData = () => {
    const idToCognitoId: { [key: number]: string } = {};
    data.forEach(({ id, cognito_id: cognitoId }) => {
      idToCognitoId[id] = cognitoId;
    });

    const cognitoIds = selectedIds.map((id) => idToCognitoId[id]);

    BulkSyncUserData.create({ cognitoIds }).then(() => {
      notify(`Successfully submitted ${cognitoIds.length} users to sync data`);
    });
  };

  return (
    <Button label="Sync User Data" disabled={false} onClick={syncData}>
      <SyncAltOutlined />
    </Button>
  );
};

export default SyncUserData;
