import { Auth } from "aws-amplify";

const settings = {
  baseUrl: "/",
  headers: {
    "Content-Type": "application/json",
  },
  // meant to hold callbacks that get called anytime a request errors
  onError: [],
  // holds callbacks that are called anytime a request is successful
  onSuccess: [],
  // meant to hold callbacks that get called after a request finishes if no other requests are in flight
  // things like hiding a loading icon would happen here
  onLastResponse: [],
  // gets called before a request goes out, displaying a loading icon may happen in these callbacks
  onRequest: [],
  // callbacks in this array get called after each response (regardless of response status)
  // with the response as an argument
  onResponse: [],
  // query parameters to always include
  queryParameters: {},
};

// Removes a default header by passing in the header name
// may be used to remove an authorization header upon logging out
export const removeHeader = (header) => {
  delete settings.headers[header];
};

// Allows configuring default settings that are used in all requests
export const configure = ({
  baseUrl,
  headers,
  queryParameters,
  onLastResponse,
  onError,
  onSuccess,
  onRequest,
  onResponse,
}) => {
  if (baseUrl) {
    settings.baseUrl = baseUrl;
  }

  if (headers) {
    settings.headers = { ...settings.headers, ...headers };
  }

  if (queryParameters) {
    settings.queryParameters = {
      ...settings.queryParameters,
      ...queryParameters,
    };
  }

  if (onError) {
    settings.onError.push(onError);
  }

  if (onSuccess) {
    settings.onSuccess.push(onSuccess);
  }

  if (onRequest) {
    settings.onRequest.push(onRequest);
  }

  if (onResponse) {
    settings.onResponse.push(onResponse);
  }

  if (onLastResponse) {
    settings.onLastResponse.push(onLastResponse);
  }
};

// makes a get request, called all because it typically is used to retrieve a collection
// of models, like all of the products for a search
export const all = (type, options = {}) => {
  return ajaxRequest({
    url: type,
    data: options,
  });
};

// alias get to all
export const get = all;

// makes a get request for a single item
export const one = (type, id) => {
  return ajaxRequest({
    url: `${type}/${id}`,
  });
};

export const create = (type, data) => {
  return ajaxRequest({
    url: type,
    method: "post",
    data,
  });
};

// alias post to create
export const post = create;

export const update = (type, id, data = {}) => {
  return ajaxRequest({
    url: `${type}/${id}`,
    method: "put",
    data,
  });
};

// alias patch to update
export const patch = update;

export const destroy = (type, id, data = {}) => {
  return ajaxRequest({
    url: `${type}/${id}`,
    method: "delete",
    data,
  });
};

// alias del to destroy, can't use delete since that's a real thing in javascript
export const del = destroy;

// create a resource and avoid repeating the type in all of the api calls
export const resource = (type) => {
  return {
    all: (options = {}) => all(type, options),
    get: (options = {}) => get(type, options),
    one: (id) => one(type, id),
    create: (data) => create(type, data),
    post: (data) => post(type, data),
    update: (id, data) => update(type, id, data),
    patch: (id, data) => patch(type, id, data),
    destroy: (id, data = {}) => destroy(type, id, data),
    del: (id, data = {}) => destroy(type, id, data),
  };
};

// keeps track of how many requests are currently happening and haven't had responses yet
let requestsInFlight = 0;

// Going to make something similar to Restangular in here to make AJAX requests spiffy
export const ajaxRequest = async ({ url, method, headers, data }) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const user = await Auth.currentAuthenticatedUser();
  const cognitoId = user.attributes.sub;
  const canopieId = user.attributes["custom:canopie_id"];

  return new Promise((resolve, reject) => {
    url = `${settings.baseUrl}${url || ""}`;
    method = method || "get";
    method = method.toLowerCase();

    const options = { method };
    options.headers = { ...settings.headers, ...(headers || {}) };

    const defaultQueryParameters = [];
    Object.keys(settings.queryParameters).forEach((key) => {
      let value = settings.queryParameters[key];

      defaultQueryParameters.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      );
    });

    if (defaultQueryParameters.length) {
      if (url.indexOf("?") === -1) {
        url = url + "?";
      } else {
        url = url + "&";
      }

      url = url + defaultQueryParameters.join("&");
    }

    if (data) {
      if (method === "get") {
        const queryParameters = [];
        Object.keys(data).forEach((key) => {
          let value = data[key];

          if (key === "query") {
            value = JSON.stringify(value);
          }

          queryParameters.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
          );
        });

        if (url.indexOf("?") === -1) {
          url = url + "?";
        } else {
          url = url + "&";
        }

        url = url + queryParameters.join("&");
      } else {
        options.body = JSON.stringify(data);
      }
    }

    const callAll = (callbacks, ...args) =>
      callbacks.forEach((cb) => cb(...args));

    requestsInFlight++;
    callAll(settings.onRequest);

    // IE doesn't support .finally on ES6 promises, so we are making this function and calling
    // it at the end of the .then and .catch callbacks
    const finallyCallback = (response) => {
      callAll(settings.onResponse, response);

      requestsInFlight--;

      if (requestsInFlight <= 0) {
        requestsInFlight = 0;
        callAll(settings.onLastResponse, response);
      }
    };

    var request = new XMLHttpRequest();
    request.open(method, url, true);

    if (method === "post") {
      request.setRequestHeader(
        "Content-Type",
        "application/json; charset=UTF-8",
      );
    }

    Object.keys(options.headers).forEach((key) => {
      request.setRequestHeader(key, options.headers[key]);
    });

    request.setRequestHeader("Authorization", `Bearer ${token}`);
    request.setRequestHeader("AWS_COGNITO_ID", cognitoId);

    if (canopieId) {
      request.setRequestHeader("CANOPIE_ID", canopieId);
    }

    request.onload = () => {
      let response;
      try {
        response = JSON.parse(request.responseText);
      } catch (e) {
        response = request.responseText;
      }

      if (request.status >= 200 && request.status < 300) {
        resolve(response);
        callAll(settings.onSuccess, response);
      } else {
        reject(response, request.status);
        callAll(settings.onError, response, request.status);
      }

      finallyCallback(response);
      return response;
    };

    request.onerror = () => {
      let response;

      try {
        response = JSON.parse(request.responseText);
      } catch (e) {
        response = request.responseText;
      }

      reject(response, request.status);
      callAll(settings.onError, response, request.status);
      finallyCallback(response);
      return response;
    };

    request.send(options.body);
  });
};

const api = {
  ajaxRequest,
  all,
  configure,
  create,
  destroy,
  one,
  patch,
  post,
  removeHeader,
  resource,
  update,
};

export default api;
