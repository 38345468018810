import React from "react";
import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useGetRecordId,
  useRecordContext,
} from "react-admin";
import { ManageDataFields } from "../utils/canopie-api";
import {
  Box,
  Button,
  FormLabel,
  TextField as MUITextField,
  Typography,
} from "@mui/material";

interface Option {
  id: number | string;
  name: string;
}

type ReferenceOptions = {
  survey_score: Option[];
  individual_survey_score: Option[];
  individual_survey_score_date: Option[];
  user: Option[];
  question_response: Option[];
  data_field: Option[];
};
type TestFormulaResponse = {
  value: string | number | null;
  inputValues: { [key: string]: string | number | null };
};
const TEST_USER_ID_LOCAL_STORAGE_KEY = "testUserId";

const UsedByDisplay = () => {
  const record = useRecordContext();

  return (
    <>
      <h4>Used By</h4>
      <ul>
        {record?.used_by.map((item: string) => <li key={item}>{item}</li>)}
      </ul>
    </>
  );
};

// we'll make a custom endpoint for manage-data-fields that returns the data_inputs with the data field
// and allows us to edit them
// we also need a "test formula" button that will allow us to test the formula against a record
export const EditDataField = () => {
  const [referenceOptions, setReferenceOptions] =
    React.useState<ReferenceOptions | null>(null);
  const [testUserId, setTestUserId] = React.useState<string | null>(
    localStorage.getItem(TEST_USER_ID_LOCAL_STORAGE_KEY) || "",
  );
  const [testFormulaResponse, setTestFormulaResponse] =
    React.useState<TestFormulaResponse | null>(null);

  const id = useGetRecordId();

  const testFormula = () => {
    localStorage.setItem(TEST_USER_ID_LOCAL_STORAGE_KEY, testUserId || "");
    ManageDataFields.one(`${id}/test-data-field?userId=${testUserId}`).then(
      setTestFormulaResponse,
    );
  };

  React.useEffect(() => {
    ManageDataFields.one("reference-options").then(setReferenceOptions);
  }, []);

  return (
    <Edit redirect={false}>
      <SimpleForm>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <UsedByDisplay />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="name" fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="default_value" fullWidth />
          </Box>
        </Box>
        <TextInput source="tooltip" fullWidth multiline />
        <ArrayInput source="data_inputs">
          <SimpleFormIterator inline>
            <TextInput source="name" />
            <SelectInput
              source="type"
              choices={[
                { id: "user", name: "User attributes" },
                { id: "individual_survey_score", name: "Survey Score" },
                {
                  id: "individual_survey_score_date",
                  name: "Survey Score Date",
                },
                { id: "survey_subscore", name: "Survey Subscore" },
                { id: "survey_score", name: "Survey Group Score" },
                { id: "question_response", name: "Question Response" },
              ]}
            />
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <>
                    {referenceOptions &&
                    scopedFormData.type in referenceOptions ? (
                      <SelectInput
                        source={getSource ? getSource("reference") : ""}
                        // @ts-ignore
                        choices={referenceOptions[scopedFormData.type]}
                      />
                    ) : (
                      <TextInput
                        source={getSource ? getSource("reference") : ""}
                      />
                    )}
                    {scopedFormData.type === "question_response" && (
                      <SelectInput
                        source={getSource ? getSource("modifier") : ""}
                        choices={[
                          { id: "sum", name: "Sum of values" },
                          { id: "date", name: "Date in MM/DD/YYYY format" },
                          {
                            id: "date_value",
                            name: "Date value for use in formula",
                          },
                          { id: "titleize", name: "First value in title case" },
                          { id: "concat", name: 'All values joined with ", "' },
                          { id: "raw", name: "All values" },
                          { id: "count", name: "Count of selected values" },
                        ]}
                      />
                    )}
                  </>
                );
              }}
            </FormDataConsumer>
            <TextInput source="default_value" />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="formula" fullWidth multiline />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1}>
            <Typography variant="caption">
              Formulas are similar to excel, see{" "}
              <a
                href="https://github.com/rubysolo/dentaku#built-in-operators-and-functions"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              for documentation on built-in functions.
              <br />
              In addition to those built in functions you can also reference
              CURRENT_YEAR for the current year, CURRENT_TIME for the current
              time in unix format, yearsAgo(dateValue) to calculate an age based
              on a date value, and REPLACE_ALL(text, search, replace) to replace
              all occurrences of search with replace.
            </Typography>
          </Box>
        </Box>
      </SimpleForm>
      <Box p={4}>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1}>
            <Typography variant="h5">
              Save to test formula with latest changes
            </Typography>
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <FormLabel>Enter user id to test with</FormLabel>
          </Box>
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <MUITextField
            value={testUserId}
            onChange={(e) => setTestUserId(e.target.value)}
          />
        </Box>
        <Button onClick={testFormula}>Test Formula</Button>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <pre>{JSON.stringify(testFormulaResponse, null, 2)}</pre>
        </Box>
      </Box>
    </Edit>
  );
};
