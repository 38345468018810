// @ts-ignore
import { PhotoshopPicker } from "react-color";
import React from "react";
import { Button, Labeled, useInput } from "react-admin";
import { ColorDisplay } from "./ColorField";

export const ColorPicker = (props: any) => {
  const [editing, setEditing] = React.useState(false);
  const { field } = useInput(props);
  const [color, setColor] = React.useState(field.value || "#fff");

  if (!editing) {
    return (
      <Labeled label={props.label || field.name}>
        <Button onClick={() => setEditing(true)}>
          <ColorDisplay color={field.value} />
        </Button>
      </Labeled>
    );
  }

  return (
    <div>
      <PhotoshopPicker
        header={field.name}
        color={color}
        onChange={(colorOptions: { hex: string }) => setColor(colorOptions.hex)}
        onAccept={() => {
          field.onChange(color);
          setEditing(false);
        }}
        onCancel={() => {
          setColor(field.value);
          setEditing(false);
        }}
      />
    </div>
  );
};

