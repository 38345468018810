import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { Box } from "@mui/material";
import { useWatch } from "react-hook-form";
import { ManageDashboardWidgets, Survey } from "../utils/canopie-api";
import { ColorPicker } from "./ColorPicker";

const WidgetOptions = () => {
  const type = useWatch({ name: "type" });
  const metricType = useWatch({ name: "configuration.metric" });
  const questionSlug = useWatch({ name: "configuration.questionSlug" });
  const [questionSlugs, setQuestionSlugs] = React.useState<
    { id: string; name: string }[]
  >([]);
  const [textInputQuestionSlugs, setTextInputQuestionSlugs] = React.useState<
    { id: string; name: string }[]
  >([]);
  const [questionOptions, setQuestionOptions] = React.useState<
    { id: string; name: string }[]
  >([]);
  const [surveys, setSurveys] = React.useState<{ id: number; name: string }[]>(
    [],
  );

  React.useEffect(() => {
    ManageDashboardWidgets.one("question-slugs").then(setQuestionSlugs);
    ManageDashboardWidgets.one("text-input-question-slugs").then(
      setTextInputQuestionSlugs,
    );
    Survey.all({ query: { scorable: true } }).then(setSurveys);
  }, []);

  React.useEffect(() => {
    if (!questionSlug || type === "inputBreakdown") {
      return;
    }

    ManageDashboardWidgets.one(`question-options/${questionSlug}`).then(
      setQuestionOptions,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSlug]);

  const metricTypesWithNumbers = [
    "usersWhoCompletedNSessions",
    "usersWhoEngagedNSessions",
    "usersWhoCompletedNDays",
  ];

  const surveyMetricTypes = [
    "usersWhoCompletedSurveys",
    "usersWhoScoredWithinRange",
  ];

  const seriesTypes = ["breakdown", "inputBreakdown"];

  if (type === "metric") {
    return (
      <>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              label="Metric"
              source="configuration.metric"
              defaultValue="totalUsers"
              fullWidth
              choices={[
                { id: "totalUsers", name: "Total Users" },
                {
                  id: "totalUsersWhoCompletedOnboarding",
                  name: "Total Users Who Completed Onboarding",
                },
                {
                  id: "usersWhoCompletedNSessions",
                  name: "Users Who Completed X Sessions",
                },
                {
                  id: "usersWhoEngagedNSessions",
                  name: "Users Who Engaged X Sessions",
                },
                {
                  id: "usersWhoCompletedNDays",
                  name: "Users Who Completed X Days",
                },
                {
                  id: "usersWhoCompletedSurveys",
                  name: "Users Who Completed Surveys",
                },
                {
                  id: "usersWhoScoredWithinRange",
                  name: "Users Who Completed Surveys Within Range",
                },
                {
                  id: "usersWithDataFieldValue",
                  name: "Users with a particular data field value",
                },
              ]}
            />
          </Box>
          {metricTypesWithNumbers.includes(metricType) && (
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <NumberInput source="configuration.number" fullWidth />
            </Box>
          )}
          {surveyMetricTypes.includes(metricType) && (
            <SelectArrayInput
              label="Surveys"
              source="configuration.surveyIds"
              fullWidth
              choices={surveys}
              optionText="name"
              optionValue="id"
            />
          )}
        </Box>
        {metricType === "usersWhoScoredWithinRange" && (
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1} ml={{ xs: 0, sm: "0.25em" }}>
              <NumberInput
                label="Minimum (leave blank for anything under max)"
                source="configuration.min"
                fullWidth
              />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.25em" }}>
              <NumberInput
                label="Maximum (leave blank for anything over min)"
                source="configuration.max"
                fullWidth
              />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.25em" }}>
              <BooleanInput
                label="Show filter users button"
                source="filterable"
                fullWidth
              />
            </Box>
          </Box>
        )}
        {metricType === "usersWithDataFieldValue" && (
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1} ml={{ xs: 0, sm: "0.25em" }}>
              <ReferenceInput
                source="configuration.dataFieldId"
                reference="data-fields"
                enableGetChoices={({ q }) => true}
                sort={{ field: "name", order: "ASC" }}
                perPage={100000}
                fullWidth
              >
                <AutocompleteInput optionText="name" fullWidth />
              </ReferenceInput>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.25em" }}>
              <TextInput
                label="Data field value"
                source="configuration.dataFieldValue"
                fullWidth
              />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.25em" }}>
              <BooleanInput
                label="Show filter users button"
                source="filterable"
                fullWidth
              />
            </Box>
          </Box>
        )}
      </>
    );
  } else if (seriesTypes.includes(type)) {
    return (
      <>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.25em" }}>
            <SelectInput
              label="Select question"
              source="configuration.questionSlug"
              fullWidth
              choices={
                type === "inputBreakdown"
                  ? textInputQuestionSlugs
                  : questionSlugs
              }
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.25em" }}>
            <SelectInput
              label="Chart type"
              source="configuration.chartType"
              fullWidth
              defaultValue="verticalBar"
              choices={[
                { id: "verticalBar", name: "Vertical Bar Chart" },
                { id: "pie", name: "Pie Chart" },
                { id: "horizontalBar", name: "Horizontal Bar Chart" },
              ]}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.25em" }} pt={2} pl={5}>
            <BooleanInput
              source="configuration.useCustomColors"
              defaultValue={true}
              label="Use custom colors"
              fullWidth
            />
          </Box>
          {type === "breakdown" && (
            <Box flex={1} mr={{ xs: 0, sm: "0.25em" }}>
              <NumberInput
                label="Limit number to top X options (set to zero to see all)"
                source="configuration.limit"
                fullWidth
              />
            </Box>
          )}
        </Box>
        {type === "breakdown" &&
          questionOptions.map((option) => (
            <Box
              display={{ xs: "block", sm: "flex", width: "100%" }}
              key={option.id}
            >
              <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                {option.name}
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                <TextInput
                  label="Override label"
                  source={`configuration.series.${option.id}.label`}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                <ColorPicker
                  label="Color"
                  source={`configuration.series.${option.id}.color`}
                  defaultValue="#4FC9DA"
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                <BooleanInput
                  source={`configuration.series.${option.id}.show`}
                  defaultValue={true}
                  label="Show"
                  fullWidth
                />
              </Box>
            </Box>
          ))}
        {type === "inputBreakdown" && (
          <>
            <ArrayInput
              source="configuration.inputSeries"
              label="Series"
              defaultValue={[]}
            >
              <SimpleFormIterator inline>
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    return (
                      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
                        <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                          <TextInput
                            source={getSource ? getSource("value") : ""}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                          <TextInput
                            source={getSource ? getSource("displayValue") : ""}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                          <TextInput
                            source={getSource ? getSource("label") : ""}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                          <ColorPicker
                            label="Color"
                            source={getSource ? getSource("color") : ""}
                            defaultValue="#4FC9DA"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
            <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                All other values
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                <TextInput
                  label="Display value"
                  source="configuration.otherValuesValue"
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                <TextInput
                  label="Label"
                  source="configuration.otherValuesLabel"
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                <ColorPicker
                  label="Color"
                  source="configuration.otherValuesColor"
                  defaultValue="#4FC9DA"
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.3em" }}>
                <BooleanInput
                  source="configuration.showOtherValues"
                  defaultValue={true}
                  label="Show"
                  fullWidth
                />
              </Box>
            </Box>
          </>
        )}
      </>
    );
  }

  return null;
};

export const EditDashboardWidget = () => {
  return (
    <Edit redirect={false}>
      <SimpleForm>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="label" fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              source="type"
              fullWidth
              defaultValue="metric"
              choices={[
                { id: "metric", name: "Metric" },
                {
                  id: "breakdown",
                  name: "Multiple Choice Question Response Chart",
                },
                { id: "inputBreakdown", name: "Input Question Response Chart" },
                { id: "spacer", name: "Spacer" },
              ]}
            />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.25em" }}>
            <NumberInput
              label="Width (in columns, max of 12 for full width)"
              source="width"
              defaultValue={3}
              fullWidth
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.25em" }}>
            <NumberInput
              label="Height (in pixels)"
              source="height"
              defaultValue={300}
              fullWidth
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.25em" }}>
            <NumberInput source="order" fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.25em" }}>
            <BooleanInput
              label="Show legend"
              source="configuration.showLegend"
              fullWidth
            />
          </Box>
        </Box>
        <WidgetOptions />
      </SimpleForm>
    </Edit>
  );
};

