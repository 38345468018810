import { configure, resource } from "./rest-api";

const error = (message) => console.error(`ERROR: ${message}`);

const apiDomain = process.env.REACT_APP_API_DOMAIN || "http://localhost:3000";

configure({
  baseUrl: `${apiDomain}/`,
});

export const notifyError = (msg) => {
  if (!msg) {
    return;
  }

  if (typeof msg !== "string") {
    if (!msg.join) {
      msg = Object.values(msg);
    }

    msg = msg.join(" ");
  }

  error(msg);
};

export const setAuthorization = (apiToken) => {
  configure({ headers: { Authorization: `Bearer ${apiToken}` } });
};

export const User = resource("admin/users");
export const Survey = resource("admin/surveys");
export const Question = resource("admin/questions");
export const QuestionOption = resource("admin/question-options");
export const Program = resource("admin/programs");
export const CompletedSession = resource("admin/completed-sessions");
export const FavoriteSession = resource("admin/favorite-sessions");
export const CommonChallengeCategory = resource(
  "admin/common-challenge-categories",
);
export const Story = resource("admin/stories");
export const RecommendedSession = resource("admin/recommended-sessions");
export const SessionRating = resource("admin/session-ratings");
export const SessionProgress = resource("admin/session-progresses");
export const QuestionResponse = resource("admin/question-responses");
export const HipaaQuestionResponse = resource("admin/hipaa-question-responses");
export const ProgramCalculator = resource("admin/calculate-program");
export const JournalEntry = resource("admin/journal-entries");
export const Mood = resource("admin/moods");
export const StaticTranslation = resource("admin/static-translations");
export const AdminResources = resource("admin/resources");
export const BulkSyncUserData = resource("v1/bulk-sync-user-data");
export const DataInput = resource("admin/data-inputs");
export const ManageDataFields = resource("admin/manage-data-fields");
export const ManageDashboardWidgets = resource(
  "admin/manage-dashboard-widgets",
);
